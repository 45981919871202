<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user }}
            </p>
            <span class="user-status">{{ empresa }} - {{ funcao }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'

import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'

import router from '@/router'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    // Navbar Components
    NotificationDropdown,
    DarkToggler,
    SearchBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: '',
      userId: null,
      funcao: '',
      userPhoto: null,
      empresa: '',
    }
  },
  async created() {
    const token1 = localStorage.getItem('token')
    if (!token1) {
      router.replace('/login')
    }
    this.user = localStorage.getItem('userName')
    this.userId = localStorage.getItem('userId')
    this.funcao = JSON.parse(localStorage.getItem('userData'))?.funcao
    this.empresa = JSON.parse(localStorage.getItem('userData'))?.empresa
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>
