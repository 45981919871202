const roles = [
  {
    title: 'Dashboard',
    icon: 'AirplayIcon',
    route: 'home',
    action: 'read',
    resource: 'dashboard',
  },
  {
    title: 'Cadastrar OS',
    icon: 'ClipboardIcon',
    route: 'ordemservico.cadastro',
    action: 'store',
    resource: 'os',
  },
  {
    title: 'Controle de Acesso',
    icon: 'ShieldOffIcon',
    route: 'access-control',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Direcionador',
    icon: 'GitPullRequestIcon',
    route: 'direcionador',
    resource: 'direcionador',
    action: 'read',
  },
  {
    title: 'Relatórios',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Métricas Atendente',
        route: 'relatorio.atendente',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'relatorio.atendente',
      },
    ],
  },
  {
    title: 'Auditor',
    icon: 'BookmarkIcon',
    route: 'auditor',
    action: 'read',
    resource: 'auditor',
  },
  {
    title: 'Fechamento',
    icon: 'PocketIcon',
    route: 'fechamento',
    action: 'read',
    resource: 'fechamento',
  },
  {
    title: 'Orçamento',
    icon: 'FileTextIcon',
    route: 'orcamento',
    resource: 'orcamento',
    action: 'read',
  },
  {
    title: 'Preventivo',
    icon: 'AlertTriangleIcon',
    route: 'preventivo',
    resource: 'preventivo',
    action: 'read',
  },
  {
    title: 'Financeiro',
    icon: 'DollarSignIcon',
    route: 'financeiro',
    resource: 'financeiro',
    action: 'read',
  },
  {
    title: 'Cadastro',
    icon: 'DatabaseIcon',
    route: 'administrador.cadastro',
    resource: 'adm/cadastro',
    action: 'read',
  },
  {
    title: 'Ordem de Serviço',
    icon: 'ListIcon',
    route: 'os',
    action: 'read',
    resource: 'os',
  },
  {
    title: 'Prestador',
    icon: 'UsersIcon',
    route: 'view.tecnico',
    action: 'read',
    resource: 'tecnico',
  },
  {
    header: 'Meus Atendimentos',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Agendar',
    icon: 'CalendarIcon',
    tag: '',
    tagVariant: 'light-warning',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Sem Contato',
    icon: 'MessageSquareIcon',
    tag: '',
    tagVariant: 'light-secondary',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Em Atendimento',
    icon: 'MessageSquareIcon',
    tag: '',
    tagVariant: 'light-primary',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Revisao',
    icon: 'AlertTriangleIcon',
    tag: '',
    tagVariant: 'light-warning',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Laudo',
    icon: 'ClipboardIcon',
    tag: '',
    tagVariant: 'light-info',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Aguardando Aprovacao',
    icon: 'ShoppingBagIcon',
    tag: '',
    tagVariant: 'light-dark',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Em Reparo',
    icon: 'ToolIcon',
    tag: '',
    tagVariant: 'light-secondary',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Reparo Concluido',
    icon: 'AwardIcon',
    tag: '',
    tagVariant: 'light-success',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Reparo Entregue',
    icon: 'TruckIcon',
    tag: '',
    tagVariant: 'light-primary',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Troca',
    icon: 'RefreshCcwIcon',
    tag: '',
    tagVariant: 'light-info',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Negado',
    icon: 'ThumbsDownIcon',
    tag: '',
    tagVariant: 'light-warning',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Cancelado',
    icon: 'XCircleIcon',
    tag: '',
    tagVariant: 'light-danger',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Todos',
    icon: 'XCircleIcon',
    resource: 'os',
    action: 'count',
  },
  {
    title: 'Empresas',
    icon: 'BriefcaseIcon',
    route: 'empresas',
    action: 'read',
    resource: 'empresa',
  },
  {
    title: 'Orçamento',
    icon: 'FileTextIcon',
    route: 'seguradora-orcamento',
    action: 'read',
    resource: 'seguradora/orcamento',
  },
  {
    title: 'Relatórios',
    icon: 'FileTextIcon',
    route: 'relatorio.seguradora.os',
    action: 'read',
    resource: 'seguradora.relatorio.os',
  },
]
const tree = roles

export default tree
