<template>
  <div>
    <a
      href="javascript:void(0)"
      @click="$refs.pesquisaAvancada.show()"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>
    <b-modal
      ref="pesquisaAvancada"
      title="Pesquisa Avançada"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <b-row>
        <b-col md="10">
          <b-input-group>
            <b-dropdown
              :text="tipoPesquisaSelecionada"
              variant="primary"
            >
              <b-dropdown-item
                :active="tipoPesquisaSelecionada === 'SINISTRO'"
                @click="setTipoPesquisa('SINISTRO')"
              >
                SINISTRO
              </b-dropdown-item>
              <b-dropdown-item
                :active="tipoPesquisaSelecionada === 'CPF/CNPJ'"
                @click="setTipoPesquisa('CPF/CNPJ')"
              >
                CPF/CNPJ
              </b-dropdown-item>
              <b-dropdown-item
                :active="tipoPesquisaSelecionada === 'NÚMERO OS'"
                @click="setTipoPesquisa('NÚMERO OS')"
              >
                NÚMERO OS
              </b-dropdown-item>
              <b-dropdown-item
                :active="tipoPesquisaSelecionada === 'NOME'"
                @click="setTipoPesquisa('NOME')"
              >
                NOME
              </b-dropdown-item>
            </b-dropdown>
            <b-form-input
              v-model="avancado"
              autofocus
              @keyup.enter="pesquisaAvancada"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="pesquisaAvancada"
              >
                <feather-icon
                  icon="SearchIcon"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        v-if="moreData"
        class="mt-2"
      >
        <b-col>
          <b-table
            sticky-header
            bordered
            small
            hover
            :items="items"
            :fields="fields"
            selectable
            empty-text="Sem dados"
            @row-selected="setOsView"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  BFormInput,
  BModal,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import router from '@/router'
import axios from '../../../../../../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BModal,
    BTable,
    BFormInput,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      avancado: '',
      tipoPesquisaSelecionada: 'SINISTRO',
      items: [],
      moreData: false,
      fields: [
        { key: 'id', label: 'id' },
        { key: 'sinistro', label: 'sinitro' },
        { key: 'numero_os', label: 'numero os' },
      ],
    }
  },
  methods: {
    setOsView(item) {
      this.clearSearch()
      this.$refs.pesquisaAvancada.hide()
      router.push({ name: 'os-view', params: { id: item[0].id } })
    },
    setTipoPesquisa(tipo) {
      this.tipoPesquisaSelecionada = tipo
    },
    pesquisaAvancada() {
      this.items = []
      const body = {
        avancado: this.formatarInput(this.avancado),
        tipo_pesquisa: this.tipoPesquisaSelecionada,
      }
      axios
        .post('api/ordem_servico/pesquisa/avancada', body)
        .then(res => {
          res.data.dados.map(item => {
            this.items.push(item)
          })
          if (this.items.length === 0) {
            this.$swal({
              title: 'ERRO',
              text: 'NENHUM DADO ENCONTRADO',
              icon: 'error',
              customClass: {
                comfirmButton: 'btn btn-danger',
              },
            })
          }
          if (this.items.length === 1) {
            this.clearSearch()
            this.$refs.pesquisaAvancada.hide()
            router.push({ name: 'os-view', params: { id: this.items[0].id } })
          } else {
            this.moreData = true
          }
        })
        .catch(error => {
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: `Ocorreu um erro durante a pesquisa: ${error.response.data.mensagem} `,
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },
    formatarInput(str) {
      str = str.trim()
      str = str.replace(/[^\w\s]/gi, '')
      return str
    },
    clearSearch() {
      this.tipoPesquisaSelecionada = 'SINISTRO'
      this.moreData = false
      this.avancado = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
</style>
