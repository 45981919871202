<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationsFiltered.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notificationsFiltered.length }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <b-link
        v-for="notification in notificationsFiltered"
        :key="notification.id"
      >
        <b-media @click="viewOsNotifcation(notification)">
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-row>
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="giveAll"
            @click="readAllNotifications"
          >
            Marcar como lida
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-info"
            block
            @click="giveAll = !giveAll"
          >
            {{ giveAll ? 'Mostrar Menos' : 'Ver Todas' }}
          </b-button>
        </b-col>
      </b-row>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BRow, BCol,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

// import { ref, onValue } from 'firebase/database'
import firebase from '@/../firebase'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
      toast,
    }
  },
  data() {
    return {
      systemNotifications: [
        // {
        //   id: 0,
        //   title: 'Sinistro - 123 - Auditada',
        //   subtitle: '150/05/2023 14:30',
        //   type: 'light-warning',
        //   icon: 'AlertTriangleIcon',
        // },
      ],
      giveAll: false,
      allNotifications: [],
    }
  },
  computed: {
    notificationsFiltered() {
      const { systemNotifications, giveAll, allNotifications } = this

      if (!giveAll) {
        return systemNotifications.filter(item => !item.notification)
      }

      return allNotifications
    },
  },
  mounted() {
    const { userId } = localStorage

    const starCountRef = firebase.ref(`notificacoes/${userId}`)
    starCountRef.on('value', snapshot => {
      const data = snapshot.val()

      const ids = this.systemNotifications.map(({ id }) => id)
      const arrayIds = Object.keys(data).filter(notId => !ids.includes(notId))

      // eslint-disable-next-line consistent-return
      arrayIds.map(id => {
        const notification = data[id]

        const notificationStyle = {
          type: notification.setor === 'Auditoria' ? 'light-warning' : 'light-success',
          icon: notification.setor === 'Auditoria' ? 'AlertOctagonIcon' : 'CheckIcon',
        }

        this.pushNotification({
          id,
          setor: notification.setor,
          title: notification.titulo || 'Nova Notificação!',
          subtitle: notification.descricao || '',
          type: notificationStyle.type,
          icon: notificationStyle.icon,
          osId: notification.os_id,
          status: notification.status,
        })
      })
    })
  },
  methods: {
    removeNotification(idNot) {
      this.systemNotifications = this.systemNotifications.filter(e => e.id !== idNot)
    },

    pushNotification(data) {
      if (!data.status) {
        this.systemNotifications = [{ ...data }, ...this.systemNotifications]
        // this.toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: data.title,
        //     icon: data.setor === 'Auditoria' ? 'AlertOctagonIcon' : 'CheckIcon',
        //     variant: data.setor === 'Auditoria' ? 'light-warning' : 'light-success',
        //   },
        // })
      }
      this.allNotifications = [{ ...data }, ...this.allNotifications]
    },

    async readNotifications(notifications) {
      const updates = {}

      notifications.map(notification => {
        const { id: notId } = notification
        const { userId } = localStorage

        updates[`notificacoes/${userId}/${notId}`] = {
          descricao: notification.subtitle,
          os_id: notification.osId,
          setor: notification.setor,
          status: true,
          titulo: notification.title,
        }

        this.removeNotification(notification.id)
      })

      await firebase.ref().update(updates)
    },

    readAllNotifications() {
      this.readNotifications(this.systemNotifications)
    },

    pushToOs(id) {
      this.$router.push({ name: 'os-view', params: { id } }).catch(() => {})
    },

    viewOsNotifcation(notification) {
      this.pushToOs(notification.osId)
    },
  },
}
</script>

<style>

</style>
