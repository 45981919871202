<template>
  <div v-if="loaded">
    <ul>
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in items"
        :key="item.header || item.title"
        :item="item"
        @menu-click="handleMenuClick"
      />
    </ul>
  </div>
  <div v-else>
    Carregando o menu...
  </div>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import axios from '../../../../../../../../axios-auth'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  data() {
    return {
      loaded: false,
    }
  },
  created() {
    const { perfil } = JSON.parse(localStorage.getItem('userData'))
    if (perfil === 'ATENDENTE') {
      const atendenteID = localStorage.getItem('userId')
      let totais = []
      axios.get(`api/atendente/totalizadores/os/${atendenteID}`)
        .then(res => {
          [totais] = res.data.dados
          if (totais !== undefined) {
            for (let i = 0; i < this.items.length; i++) {
              const currentItem = this.items[i]
              if (currentItem.title) {
                const key = currentItem.title.toLowerCase().replace(/\s/g, '_')
                if (totais[key]) {
                  currentItem.tag = totais[key]
                }
              }
            }
          }
          this.loaded = true
        })
    } else {
      this.loaded = true
    }
  },
  methods: {
    handleMenuClick(item) {
      const { perfil } = JSON.parse(localStorage.getItem('userData'))
      if (perfil === 'ATENDENTE') {
        this.$store.commit('SET_SELECTED_MENU', item.title)
      }
    },
  },
}
</script>
